.logo {
  font-size: $font-26;
  @extend .font-black;

  .icon {
    // width: 40px;
    // height: 40px;
    margin-right: 8px;
    // background-color: $dark;
    // clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);

    + span {
      position: relative;
      top: -4px;
    }

    img {
      @include maxDevice(810px) {
        width: 140px;
      }
      @include maxDevice(790px) {
        width: 125px;
      }
    }
  }
}
.navbar {
  // background: linear-gradient(180deg, rgba($white, 0.16) 0%, rgba($white, 0.04) 100%);
  // backdrop-filter: blur(10px);
  padding-bottom: 30px;
  background: $white;
  padding-top: 30px;
  width: 100%;
  z-index: 5;

  @include maxDevice(991px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include maxDevice(767px) {
    padding-top: 8px;
    padding-bottom: 8px;
    overflow-x: hidden;
    // box-shadow: 0px 8px 24px rgba(105,112,119, 0.152931);
  }

  &-brand {
    padding: 0;

    @include maxDevice(767px) {
      min-height: 56px;
      display: flex;

      img {
        margin: auto;
      }
    }
  }

  &-toggler {
    top: 8px;
    right: -4px;
    width: 64px;
    height: 56px;
    position: absolute;
    background: transparent url("../assets/images/menubg.svg") no-repeat center
      right / contain;

    &:not(.collapsed) {
      img:first-child {
        display: none;
      }
    }

    &.collapsed {
      img + img {
        display: none;
      }
    }
  }

  &-nav {
    a {
      color: $dark;
      padding-top: 0;
      font-weight: 600;
      padding-bottom: 0;
      line-height: 22px;
      font-size: $font-14;
      text-transform: uppercase;

      &:hover,
      &:active,
      &:focus {
        color: #7b3fe4;
      }
      @include maxDevice(767px) {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
        line-height: 32px;
        font-weight: 600;
        margin-top: 20px;
        font-size: 20px;
      }

      + a {
        margin-left: 32px;

        @include maxDevice(991px) {
          margin-left: 10px;
        }

        @include maxDevice(767px) {
          margin-top: 0;
          margin-left: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}
