// Flexbox display
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Inline flex display
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// row (default) | row-reverse | column | column-reverse
@mixin flex-direction($direction: row) {
  @if $direction == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
       -moz-box-direction: reverse;
       -moz-box-orient: horizontal;
  } @else if $direction == column {
    -webkit-box-direction: normal;
     -webkit-box-orient: vertical;
       -moz-box-direction: normal;
        -moz-box-orient: vertical;
  } @else if $direction == column-reverse {
    -webkit-box-direction: reverse;
      -webkit-box-orient: vertical;
       -moz-box-direction: reverse;
         -moz-box-orient: vertical;
  } @else {
     -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
        -moz-box-direction: normal;
       -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

// <number>
@mixin flex-grow($int: 1) {
   -webkit-box-flex: $int;
      -moz-box-flex: $int;
  -webkit-flex-grow: $int;
           -ms-flex: $int;
          flex-grow: $int;
}

// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
       -moz-box-align: start;
       -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
       -moz-box-align: end;
       -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
       -moz-box-align: $value;
       -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
          align-items: $value;
}

// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
       -moz-box-pack: start;
       -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
       -moz-box-pack: end;
       -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
       -moz-box-pack: justify;
       -ms-flex-pack: justify;
  } @else if $value == space-around {
       -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
       -moz-box-pack: $value;
       -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
          justify-content: $value;
}

// transition
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

// translate
@mixin translate($x, $y){
    -moz-transform:    translate($x, $y);
    -o-transform:      translate($x, $y);
    -webkit-transform: translate($x, $y);
    transform:         translate($x, $y);
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg...){
    -moz-transform:    rotate($deg);
    -o-transform:      rotate($deg);
    -webkit-transform: rotate($deg);
    transform:         rotate($deg);
}

// keyframes
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}
// media query
@mixin maxDevice($device){
	@media screen and (max-width: $device){
		@content;
	}
}

@mixin minDevice($device){
	@media screen and (min-width: $device){
		@content;
	}
}