$primary: #121619;
$purple: #7b3fe4;
$blue: #2081e2;
$grey-100: #a2a9b0;
$grey-60: #dde1e6;
$grey-69: #697077;

$white: #fff;
$dark: #000;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.font {
  &-light {
    font-weight: 300;
  }
  &-normal {
    font-weight: 400;
  }
  &-medium {
    font-weight: 500;
  }
  &-bold {
    font-weight: 700;
  }
  &-black {
    font-weight: 600;
  }
}

$font-10: 10px;
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-24: 24px;
$font-26: 26px;
$font-32: 32px;
$font-40: 40px;
$font-48: 48px;
$font-72: 72px;

html {
  font-size: 100.01%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: "Satoshi";
  font-size: $font-18;
  min-height: 100vh;
  line-height: 1.7;
  color: $primary;

  @extend .font-normal;

  @include maxDevice(767px) {
    font-size: $font-16;
  }
}

a {
  color: $dark;
  display: inline-block;

  &:hover {
    color: $purple;
  }
}

a,
.btn,
button,
input,
select {
  outline: none !important;
}

em {
  font-weight: normal;
  font-style: italic;
}

hr {
  opacity: 1;
  border-top-color: $grey-60;
}

.lead {
  font-size: $font-20;
  @extend .font-normal;

  @include maxDevice(767px) {
    font-size: $font-18;
  }
}

p,
ul {
  opacity: 1;
  font-size: 1em;
  margin: 0 0 2em;
  @extend .font-normal;

  @include maxDevice(1199px) {
    margin-bottom: 1.6em;
  }

  + p {
    margin-top: -0.4em;
  }

  small {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.1px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

strong {
  @extend .font-bold;
}

.container {
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  &-md {
    max-width: 1040px;
  }

  &-sm {
    max-width: 864px;
  }
}

.dropdown-noarrow:after {
  display: none;
}

.overflow-visible {
  overflow: visible !important;
}

.nowrap {
  white-space: nowrap;
}

a {
  @include transition(all 0.35s linear);
}

strong {
  font-weight: bold;
}

button,
input {
  &:focus {
    box-shadow: none !important;
  }
}

.btn {
  @include transition(all 0.35s linear);
  box-shadow: none !important;
  text-transform: uppercase;
  border-radius: 25px;
  font-size: $font-14;
  @extend .font-black;
  position: relative;
  padding: 13px 20px;
  line-height: 22px;

  + .btn {
    margin-left: 20px;
  }

  &-lg {
    min-width: 240px;
  }

  &-reset {
    background: none !important;
    border: none !important;
    padding: 0 !important;
  }

  &-xs {
    letter-spacing: 1.2px;
    font-size: $font-10;
    line-height: 12px;
    padding: 6px 8px;
    text-indent: 2px;
    min-width: 64px;
  }

  &-link {
    padding: 0;
    border-radius: 0;
    text-decoration: none;
  }

  &-solid {
    background-color: $primary;
    border-color: $primary;
    color: $white;

    &:hover {
      background-color: #7b3fe4;
      border-color: #7b3fe4;
      color: #fff;
    }
  }
}

.bg {
  &-shape {
    background: transparent url("../assets/images/waveFilled_01.svg") no-repeat
      center / 120%;
    background-attachment: fixed;

    @include maxDevice(767px) {
      background-size: 300%;
    }
  }

  &-purple {
    background-color: $purple;
    color: $white;
  }
}

.text {
  &-white {
    a {
      color: inherit;
    }
  }

  &-purple {
    color: $purple;
  }

  &-muted {
    color: $grey-100 !important;
  }
}

.pt {
  &-40 {
    padding-top: 40px;
  }
  &-60 {
    padding-top: 60px;

    @include maxDevice(1199px) {
      padding-top: 42px;
    }

    @include maxDevice(767px) {
      padding-top: 32px;
    }
  }
  &-100 {
    padding-top: 100px;

    @include maxDevice(1199px) {
      padding-top: 60px;
    }

    @include maxDevice(767px) {
      padding-top: 42px;
    }
  }
}

.pb {
  &-40 {
    padding-bottom: 40px;

    @include maxDevice(1199px) {
      padding-bottom: 22px;
    }

    @include maxDevice(767px) {
      padding-bottom: 16px;
    }
  }
  &-60 {
    padding-bottom: 60px;

    @include maxDevice(1199px) {
      padding-bottom: 42px;
    }

    @include maxDevice(767px) {
      padding-bottom: 32px;
    }
  }
  &-80 {
    padding-bottom: 80px;

    @include maxDevice(1199px) {
      padding-bottom: 48px;
    }

    @include maxDevice(767px) {
      padding-bottom: 40px;
    }
  }
}

.pl-0 {
  padding-left: 0;
}

.ml-2 {
  margin-left: 20px;
}
.mt {
  &-24 {
    margin-top: 24px;

    @include maxDevice(1199px) {
      margin-top: 20px;
    }

    @include maxDevice(767px) {
      margin-top: 18px;
    }
  }
}

.mb {
  &-24 {
    margin-bottom: 24px;

    @include maxDevice(1199px) {
      margin-bottom: 20px;
    }

    @include maxDevice(767px) {
      margin-bottom: 18px;
    }
  }

  &-40 {
    margin-bottom: 40px;

    @include maxDevice(1199px) {
      margin-bottom: 32px;
    }

    @include maxDevice(767px) {
      margin-bottom: 26px;
    }
  }

  &-60 {
    margin-bottom: 60px;

    @include maxDevice(1199px) {
      margin-bottom: 42px;
    }

    @include maxDevice(767px) {
      margin-bottom: 32px;
    }
  }

  &-80 {
    margin-bottom: 80px;

    @include maxDevice(1199px) {
      margin-bottom: 48px;
    }

    @include maxDevice(767px) {
      margin-bottom: 40px;
    }
  }
}
