@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Black.eot");
  src: url("../assets/fonts/Satoshi-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Satoshi-Black.woff2") format("woff2"),
    url("../assets/fonts/Satoshi-Black.woff") format("woff"),
    url("../assets/fonts/Satoshi-Black.ttf") format("truetype"),
    url("../assets/fonts/Satoshi-Black.svg#Satoshi-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Regular.eot");
  src: url("../assets/fonts/Satoshi-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../assets/fonts/Satoshi-Regular.woff") format("woff"),
    url("../assets/fonts/Satoshi-Regular.ttf") format("truetype"),
    url("../assets/fonts/Satoshi-Regular.svg#Satoshi-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Bold.eot");
  src: url("../assets/fonts/Satoshi-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../assets/fonts/Satoshi-Bold.woff") format("woff"),
    url("../assets/fonts/Satoshi-Bold.ttf") format("truetype"),
    url("../assets/fonts/Satoshi-Bold.svg#Satoshi-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Medium.eot");
  src: url("../assets/fonts/Satoshi-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../assets/fonts/Satoshi-Medium.woff") format("woff"),
    url("../assets/fonts/Satoshi-Medium.ttf") format("truetype"),
    url("../assets/fonts/Satoshi-Medium.svg#Satoshi-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Light.eot");
  src: url("../assets/fonts/Satoshi-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Satoshi-Light.woff2") format("woff2"),
    url("../assets/fonts/Satoshi-Light.woff") format("woff"),
    url("../assets/fonts/Satoshi-Light.ttf") format("truetype"),
    url("../assets/fonts/Satoshi-Light.svg#Satoshi-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
