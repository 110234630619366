.footer{
    color: $primary;
    font-size: $font-14;
    padding: 40px 0 50px;
    @extend .font-normal;
    background: linear-gradient(180deg, rgba(221, 225, 230, 1) 0%, rgba(255, 255, 255, 0) 100%);

    .border-bottom{
        padding: 0;
        line-height: 1;
        text-decoration: none;
        border-bottom-width: 2px !important;
        border-bottom-color: currentColor !important;
    }

    .logo{
        font-size: $font-20;
        
        // .icon{
        //     width: 32px;
        //     height: 32px;
        // }
        
    }
}