h1 {
  font-size: $font-72;
  @extend .font-black;
  line-height: $font-72;
  letter-spacing: -1.6px;

  @include maxDevice(991px) {
    font-size: $font-48;
    line-height: $font-48;
  }

  @include maxDevice(575px) {
    font-size: $font-32;
    line-height: $font-32;
  }
}

h2 {
  margin-bottom: 24px;
  font-size: $font-48;
  @extend .font-black;
  line-height: 56px;

  @include maxDevice(991px) {
    font-size: $font-32;
    margin-bottom: 16px;
    line-height: 40px;
  }
}

h3 {
  margin-bottom: 24px;
  font-size: $font-40;
  @extend .font-black;
  line-height: 48px;

  @include maxDevice(767px) {
    font-size: $font-32;
    margin-bottom: 16px;
    line-height: 40px;
  }
}

h4 {
  margin-bottom: 24px;
  font-size: $font-32;
  @extend .font-black;
  line-height: 40px;

  @include maxDevice(767px) {
    font-size: $font-26;
    margin-bottom: 16px;
    line-height: 34px;
  }
}

.h4 {
  margin-bottom: 24px;
  font-size: $font-24;
  @extend .font-black;
  line-height: 32px;

  @include maxDevice(767px) {
    font-size: $font-20;
    margin-bottom: 16px;
    line-height: 28px;
  }
}

h5 {
  font-size: $font-20;
  @extend .font-black;
  margin-bottom: 16px;
  line-height: 28px;
}

h6.heading {
  font-size: $font-16;
  @extend .font-black;
  margin-bottom: 16px;
  line-height: 24px;
}

.page {
  &-content {
    padding-top: 105px;

    @include maxDevice(991px) {
      padding-top: 84px;
    }

    @include maxDevice(767px) {
      padding-top: 68px;
    }
  }

  &-information {
    h5 {
      margin-bottom: 24px;
      font-size: $font-24;
      @extend .font-black;
      line-height: 32px;

      @include maxDevice(767px) {
        font-size: $font-20;
        margin-bottom: 16px;
        line-height: 28px;
      }
    }

    img {
      margin: 0 auto 40px;
      max-width: 100%;
      display: block;
      width: 100%;

      @include maxDevice(767px) {
        margin-bottom: 32px;
      }
    }

    ul {
      padding-left: 0;
      list-style: none;

      li {
        margin-bottom: 12px;
        padding-left: 28px;
        position: relative;

        &:before {
          left: 0;
          top: 10px;
          width: 11px;
          content: "";
          height: 12px;
          position: absolute;
          border-radius: 0.6px;
          background-color: $purple;
          clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
        }
      }
    }
  }
}

.col-grid {
  position: relative;
  z-index: 1;

  @include maxDevice(991px) {
    .row {
      > div {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &-image {
    @include maxDevice(991px) {
      position: relative;
      z-index: 1;
    }
  }

  h2 {
    position: relative;
    padding-bottom: 0;
    height: 29.1vw;
    color: $white;
    z-index: 1;

    @include maxDevice(1199px) {
      font-size: $font-40;
      line-height: 48px;
    }

    @include maxDevice(991px) {
      font-size: $font-48;
      line-height: 56px;
      padding: 0 25px;
      left: 0;
      top: 50%;
      height: auto;
      position: absolute;
      @include translate(0, -50%);
    }

    @include maxDevice(575px) {
      font-size: $font-32;
      line-height: 40px;
      padding: 0 15px;
    }

    span {
      @include minDevice(992px) {
        @include translate(0, -50%);
        position: absolute;
        top: 50%;
        left: 0;
      }
    }
  }

  img {
    position: absolute;
    z-index: -1;
    top: 0;

    @include maxDevice(991px) {
      position: relative;
      z-index: 1;
    }
  }

  &-left {
    @include maxDevice(991px) {
      @include flexbox();
      @include justify-content(flex-start);
    }
    img {
      width: 50.6%;
      left: 0;

      @include maxDevice(991px) {
        width: calc(100% + 55px);
        left: -32px;
      }
    }
  }

  &-right {
    @include maxDevice(991px) {
      @include flexbox();
      @include justify-content(flex-end);
    }
    img {
      width: 50.6%;
      right: 0;

      @include maxDevice(991px) {
        width: calc(100% + 55px);
        right: -32px;
      }
    }
  }
}

.divider {
  margin-top: -5.4%;

  img {
    width: 137%;
    max-width: 137%;
    margin-left: -19%;
  }
}

.section {
  &-subheading {
    margin-bottom: 24px;

    h6 {
      font-size: $font-14;
      @extend .font-black;
      line-height: 22px;
    }

    &-sm {
      margin-bottom: 8px;

      h6 {
        line-height: 12px;
        font-size: $font-10;
        letter-spacing: 1.2px;
      }
    }
  }
}

.swiper {
  &-button {
    &-prev,
    &-next {
      width: 20px !important;
      height: 20px !important;
      background: transparent url("../assets/images/arrow-square-right.svg")
        no-repeat center center / auto;

      &:after {
        display: none;
      }
    }
  }
}

.card {
  width: 90%;
  border: none;
  max-width: 290px;
  overflow: hidden;
  margin: 15px auto 40px;
  border-radius: 12px;
  @include translate(0, 0);
  background-color: $white;
  @include transition(all 0.3s linear);
  box-shadow: 0px 8px 16px rgba($grey-69, 0.152931);

  &:hover {
    @include translate(0, -5px);
  }

  &-btn {
    position: absolute;
    z-index: 1;
    left: 20px;
    top: -31px;
  }

  &-image {
    padding-bottom: 100%;
    position: relative;
    &.loading {
      padding-bottom: 0;
    }
    img {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  &-body {
    position: relative;
    padding: 40px 24px 24px;

    h6 {
      margin: 0;
      color: $grey-100;
      line-height: 12px;
      @extend .font-black;
      font-size: $font-10;
      letter-spacing: 1.2px;
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: $purple;

        &:hover {
          //letter-spacing: 1.6px;
          text-decoration: underline;
        }
      }
    }

    h4 {
      margin: 8px 0;
      color: $primary;
      line-height: 24px;
      font-size: $font-16;
      @extend .font-black;
    }

    hr {
      margin: 24px 0;
    }
  }
}

.price {
  font-size: $font-16;
  @extend .font-black;
  line-height: 24px;
}

.shape {
  &-01 {
    position: absolute;
    right: -85px;
    z-index: -1;
    bottom: 0;
  }

  &-02 {
    position: absolute;
    left: -85px;
    z-index: -1;
    top: 0;
  }
}

.swipe {
  &-outer {
    position: relative;
  }

  &-btns {
    position: absolute;
    top: -30px;
    right: 0;

    img {
      display: block;
    }

    &-prev {
      img {
        @include rotate(180deg);
      }

      &[disabled] {
        opacity: 0.5;
      }
    }

    button {
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}

.how-works {
  margin-top: -2.6%;
}

.input {
  &-group {
    padding: 20px 24px;
    border-radius: 12px;
    border: 1px solid $grey-60;

    @include maxDevice(991px) {
      padding: 15px 20px;
    }

    .btn {
      padding: 0;

      img {
        display: block;
      }
    }

    .form-control {
      color: $primary;
      padding: 0 0 0 24px;
      font-size: $font-16;
      border-radius: 12px;
      @extend .font-normal;

      @include maxDevice(991px) {
        padding-left: 20px;
      }
    }
  }
}

.dropdown {
  > .btn {
    width: 100%;
    color: $primary;
    padding: 12px 16px;
    font-size: $font-16;
    border-radius: 12px;
    @extend .font-normal;
    text-transform: none;
    background-color: $white;
    border: 1px solid $grey-60;

    &:after {
      display: none;
    }
  }

  &-image {
    width: 40px;
    height: 40px;
    margin-right: 16px;

    @include maxDevice(575px) {
      margin-right: 8px;
      width: 25px;
      height: 25px;
    }
  }

  &-menu {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    overflow: auto;
    max-height: 320px;
    min-width: 290px;
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0px 16px 32px rgba($grey-69, 0.5);

    a,
    .dropdown-item {
      cursor: pointer;
      color: $primary;
      line-height: 28px;
      padding: 16px 24px;
      font-size: $font-16;
      @extend .font-normal;

      @include maxDevice(767px) {
        padding: 12px 15px;
        font-size: $font-14;

        span {
          max-width: 45%;
        }
      }

      @include maxDevice(575px) {
        span {
          max-width: 36%;
        }
      }

      &:focus {
        background-color: $grey-60;
        color: $primary;
      }

      + a,
      + .dropdown-item {
        border-top: 1px solid $grey-60;
      }
    }
  }
}

.artist {
  &-detail {
    // box-shadow: 0px 40px 40px rgba($grey-60, 0.45);
    // background-color: $white;

    hr {
      margin: 0;
      border-top-color: rgba(221, 225, 230, 1);
    }

    .inner {
      // border-bottom: 1px solid $grey-60;

      // RGB(221, 225, 230)
    }
  }

  &-desc {
    color: $grey-60;
    opacity: 1;
    color: rgba(162, 169, 176, 1);
    font-size: 10px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 2px;
    text-align: left;
    line-height: 12px;
    text-transform: uppercase;
  }
}

.socials {
  a {
    margin-left: 8px;
  }
}

.table {
  backdrop-filter: blur(10px);
  border-radius: 12px !important;
  background-color: RGBA(242, 244, 248, 0.5);

  th {
    color: $primary;
    line-height: 12px;
    white-space: nowrap;
    font-size: $font-10;
    @extend .font-black;
    letter-spacing: 1.2px;
    padding: 24px 24px 16px;
    text-transform: uppercase;
    border-bottom: 1px solid $grey-60;

    @include maxDevice(991px) {
      padding: 18px 18px 14px;
    }
  }

  td {
    color: $primary;
    line-height: 28px;
    padding: 15px 24px;
    font-size: $font-16;
    white-space: nowrap;
    @extend .font-normal;
    border-bottom: 1px solid $grey-60;

    @include maxDevice(991px) {
      padding: 12px 18px;
    }

    strong {
      @extend .font-black;
    }

    a {
      line-height: 1.2;
      text-decoration: none;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom-color: $purple;
      }
    }
  }

  tr {
    &:last-child {
      td {
        border-bottom: none !important;
      }
    }
  }
}

.audio {
  &-image {
    padding-bottom: 100%;
    position: relative;
    .btn {
      bottom: -32px;
      left: 20px;
      top: auto;
    }

    img {
      border-radius: 12px 12px 0 0;
    }

    img.cover {
      border-radius: 12px 12px 0 0;
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  &-control {
    line-height: 12px;
    font-size: $font-10;
    @extend .font-black;
    letter-spacing: 1.2px;
    padding: 55px 24px 40px;
    text-transform: uppercase;
    border-radius: 0 0 12px 12px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 12px !important;
    background-color: RGBA(242, 244, 248, 0.5);

    .progress {
      height: 4px;
      flex-grow: 1;
      margin: 0 16px;
      border-radius: 2px;
      background-color: $grey-60;

      &-bar {
        border-radius: 2px;
        background: linear-gradient(
          180deg,
          rgba(123, 63, 228, 1) 0%,
          rgba(75, 23, 165, 1) 100%
        );
      }
    }
  }
}

.details {
  h6 {
    color: $purple;
    text-transform: uppercase;

    a,
    .btn {
      line-height: 1;
      font-size: $font-10;
      @extend .font-black;
      text-decoration: none;
      letter-spacing: 1.2px;
      color: $purple !important;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 22px;
    @extend .font-black;
    text-transform: uppercase;
  }
}

.desc {
  padding: 24px;
  line-height: 24px;
  font-size: $font-16;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 12px !important;
  background-color: RGBA(242, 244, 248, 0.5);
  @include maxDevice(1199px) {
    padding: 20px;
  }

  @include maxDevice(767px) {
    padding: 15px;
  }
}

.property {
  line-height: 24px;
  font-size: $font-16;
  @extend .font-normal;

  padding: 16px;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 12px !important;
  background-color: RGBA(242, 244, 248, 0.5);

  h6 {
    margin: 0;
    color: $primary;
    line-height: 12px;
    font-size: $font-10;
    @extend .font-black;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
}

.purchase {
  &-card {
    padding: 24px;
    position: relative;
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      rgba(123, 63, 228, 1) 0%,
      rgba(75, 23, 165, 1) 100%
    );

    z-index: 2;
    &-sold {
      background: linear-gradient(
        180deg,
        rgba(32, 129, 226, 1) 0%,
        rgba(24, 105, 185, 1) 100%
      );

      .dropdown {
        > .btn {
          color: $blue !important;
        }
      }
    }

    h6 {
      color: $white;
      line-height: 12px;
      font-size: $font-10;
      @extend .font-black;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }

    .progress {
      height: 10px;
      flex-grow: 1;
      border-radius: 5px;
      margin-bottom: 24px;
      background-color: rgba($primary, 0.3);

      &-bar {
        border-radius: 5px;
        background: $white;
      }
    }

    .price {
      font-size: $font-32;
      @extend .font-black;
      line-height: 40px;
      color: $white;
    }

    .dropdown {
      position: static;

      > .btn {
        color: $purple;
        line-height: 12px;
        padding: 6px 20px;
        font-size: $font-10;
        @extend .font-black;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        background-color: $white !important;
        border: 0;
      }

      &-menu {
        transform: translate(0, 0) !important;
        top: 100% !important;
        margin-top: 0px;
        left: 0;

        .btn-xs {
          white-space: nowrap;
          padding: 6px 18px;

          @include maxDevice(767px) {
            padding: 6px 10px;
          }
        }

        a,
        .dropdown-item {
          background-color: $white !important;
          white-space: normal;
          line-height: 1.2;
          cursor: initial;

          strong {
            margin: 0 3px;
          }

          img {
            &:first-child {
              min-width: 24px;
              margin-right: 12px;
            }

            ~ img {
              max-height: 12px;
            }
          }
        }
      }
    }
  }
}

blockquote {
  border-left: 8px solid $purple;
  font-size: $font-18;
  padding: 16px 32px;
  @extend .font-bold;
  line-height: 28px;
  border-radius: 4px;
  margin-bottom: 40px;

  @include maxDevice(767px) {
    margin-bottom: 32px;
    font-size: $font-16;
    padding: 10px 20px;
    line-height: 26px;
  }
}

//x
.mask {
  width: 40px;
  height: 40px;
  mask-image: url(../assets/images/profile-mask.svg);
  mask-size: 82%;
  mask-repeat: no-repeat !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-position: center;

  img {
    object-fit: cover;
    width: 40px;
    height: 40px;
  }
}

.mask2 {
  width: 92px;
  height: 84px;
  mask-image: url(../assets/images/profile-mask.svg);
  mask-size: 82%;
  mask-repeat: no-repeat !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-position: center;

  img {
    object-fit: cover;
    width: 92px;
    height: 92px;
    margin-top: -4px;
  }
}

.mask3 {
  width: 92px;
  height: 84px;
  mask-image: url(../assets/images/profile-mask.svg);
  mask-size: 82%;
  mask-repeat: no-repeat !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-position: center;
  margin: 0 auto;
  margin-bottom: 8px;

  img {
    object-fit: cover;
    width: 92px;
    height: 92px;
    margin-top: -4px;
  }
}

.modal {
  &-dialog {
    @include minDevice(640px) {
      max-width: 620px;
      width: 100%;
    }
  }

  &-content {
    border: none;
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0px 16px 32px rgba(105, 112, 119, 0.5);
  }

  &-body {
    padding: 40px 20px;
    font-weight: 400;
    font-size: $font-16;

    .btn {
      min-width: 240px;
    }

    @include maxDevice(575px) {
      padding: 30px 15px;
      font-size: $font-14;
    }

    h6 {
      color: $purple;
      line-height: 12px;
      margin-bottom: 5px;
      @extend .font-black;
      font-size: $font-10;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }

    .code {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(162, 169, 176, 1);
    }

    .modal-box {
      border-radius: 12px;
      background-color: rgba(242, 244, 248, 0.5);
      margin-bottom: 32px;
      padding: 24px;
      p {
        margin-bottom: 24px;
      }
      .error-text {
        margin-top: -16px;
        color: red;
        margin-bottom: 16px;
      }
      input[type="email"] {
        background-color: red;
        border-radius: 12px;
        border: 1px solid rgba(221, 225, 230, 1);
        background-color: rgba(255, 255, 255, 1);
        width: 290px;
        max-width: 100%;
        opacity: 1;
        color: rgba(18, 22, 25, 1);
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 24px;
        height: 48px;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 16px;
      }
    }
  }

  &-icon {
    margin-bottom: 16px;
    position: relative;

    &-front {
      @include translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
  .btn-disconnect {
    display: block;
    color: #7b3fe4;
    line-height: 12px;
    margin-bottom: 5px;
    font-size: 10px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    border: 0;
    background-color: transparent;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 3px;
  }
}

.buy-on-opensea {
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(32, 129, 226, 1);
  font-family: "Satoshi";
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1, 2px;
  text-align: center;
  line-height: 12px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 16px;
  margin-top: 4px;
  &:hover,
  &:active,
  &:focus {
    color: rgba(32, 129, 226, 1);
  }
}

.crossmintImg-0-2-2 {
  display: none;
}

.loading-box {
  animation: spin 3s linear infinite;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  margin-top: -2px;
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-solid:hover {
  .loading-box {
    filter: invert(1);
  }
}

.crossmintButton-0-2-1 {
  transition: all 0.35s linear !important;
  white-space: initial !important;
  @include maxDevice(1063px) {
    margin-left: 0 !important;
    margin-top: 11px;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #7b3fe4;
    color: #fff;
    opacity: 1 !important;
  }
}

.payment-desc {
  color: rgba(162, 169, 176, 1);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 18px;
}

.buy-button {
  @include maxDevice(1063px) {
    margin-left: 0 !important;
    margin-top: 11px;
    width: 100%;
  }
}

.error-page {
  text-align: center;
  margin-top: 32px;
  img {
    position: initial;
    margin-bottom: 40px;
    @include maxDevice(900px) {
      width: 200px;
      max-width: 100%;
    }
  }
  h1 {
    color: rgba(18, 22, 25, 1);
    font-family: "Satoshi";
    font-size: 56px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 64px;
    margin-bottom: 40px;
    @include maxDevice(900px) {
      font-size: 36px;
      line-height: 1.2;
    }
  }
  p {
    color: rgba(18, 22, 25, 1);
    font-family: "Satoshi";
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 40px;
  }
}

#w3m-modal {
  z-index: 100000;
}

.modal-close-button {
  border: 0;
  background: transparent;
  position: absolute;
  right: 5px;
  top: 0;
  padding: 16px;
  appearance: initial;
  z-index: 10;
  img {
    filter: invert(100%);
  }
}

.items-list {
  &-item {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 40px;

    .item-box {
      width: 110px;
      @include maxDevice(760px) {
        width: 50px;
      }
    }
    .item-body {
      flex: 1;
      padding-left: 40px;
      h5 {
        margin-bottom: 16px;
      }
    }
  }
}

.featured-artist {
  .featured-img {
    border-radius: 12px;
  }
  a {
    text-decoration: none;
  }
}
